.App {
  width: 100vw;
  height: 100vh; 
  display: flex;
  flex-direction: row;
}

.Content {
 width:90%;
 height:100vh;
 overflow:auto;
 padding:40px;
 #background-color:#eaeaea;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  margin:0;
  padding:0;
}

.Sidebar{
 height:100%;
 width:320px;
 background-color:#2F4050;
 overflow: auto;
 font-size:25px;
}

.SidebarList {
height:auto;
padding:0;
width:100%;
}

.SidebarList .row{
display: flex;
flex-direction: row;
width:100%;
height:60px;
color:white;
list-style-type:none;
margin: 0;
justify-content: center;
align-items: center;
font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.SidebarList #active{
background-color:#293846

}

.SidebarList .row:hover{
cursor:pointer;
background-color:#293846
}


.row #icon{
flex:30%;
display:grid;
place-items:center;

}

.row #title{
flex:70%;
}

td {
  text-align: center;
  vertical-align: middle;
}

th {
  text-align: center;
  vertical-align: middle;
}

textarea {
   font-size: 20pt;
}

span.version {
  font-size: 8pt;
  color:#a0a0a0;
}
